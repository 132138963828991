.root {
  align-items: center;
  display: flex;
  height: 32px;
  padding-inline-end: 8px;
  color: rgba(0, 0, 0, 0.6);
}

.root.isSelected {
  color: #1967d2;
  background-color: var(#1967d2, rgba(0, 0, 0, 0.5));
}

.expandIconWrapper {
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
  transform: rotate(90deg);
}

.labelGridItem {
  padding-inline-start: 8px;
  text-align: left;
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
}